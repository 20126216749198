import React from 'react';
import {routes, restricted_routes} from "./functions/conf/routes";
import AuthenticationFail from "./pages/Authentication/fail";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Base64} from "js-base64"
import Cookies from "js-cookie";
import {checkToken} from "./functions/security/security";
import Navbar from "./components/Navigation/navbar";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authData: this.props.authData,
            authTokenIsCorrect: true,
            routerHistory: require("history").createBrowserHistory(),
            loggedUserData: JSON.parse(Base64.decode(Cookies.get("sso-holidays-data"))),
        }
    }

    componentDidMount() {
        this.state.routerHistory.listen((location, action) =>
            new Promise((resolveToken) => checkToken(Cookies.get("sso-holidays-token"), resolveToken))
                .then((token) => this.setState({authTokenIsCorrect: token !== null}))
        )
    }

    render() {

        if (this.state.authTokenIsCorrect) {
            let enabled_routes = (this.state.loggedUserData.roles.includes("ROLE_ADMIN")) ? restricted_routes : routes;

            return (
                <Router history={this.state.routerHistory}>
                    <Navbar userFirstName={this.state.authData.firstName} userLastName={this.state.authData.lastName}/>

                    <div id={"app_content"}>
                        <Routes>
                            {enabled_routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={route.comp}
                                />
                            ))}
                        </Routes>
                    </div>
                </Router>
            );
        } else
            return (
                <div id={"app_content"}>
                    <AuthenticationFail/>
                </div>
            );
    }
}