import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import "./paginate.scss";

export default class Paginate extends React.Component {
    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(event, value) {
        this.props.onChange(event, value);
    }

    render() {
        return (
            <Box className={"pagination"}>
                <Pagination
                    page={Number(this.props.page)}
                    count={this.props.count}
                    shape="round"
                    color="standard"
                    size="large"
                    showFirstButton
                    showLastButton
                    boundaryCount={1}
                    siblingCount={0}
                    onChange={this.handlePageChange}
                />
            </Box>
        );
    }
}