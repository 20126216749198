import React from "react";
import './style.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faPlus, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {logout} from "../../../functions/security/security";

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSettingsOpen: false
        };
        this.toggleSettingsMenu = this.toggleSettingsMenu.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', (e) => this.triggerClickOutside(e));
    }

    componentWillUnmount() {
        window.removeEventListener('click', (e) => this.triggerClickOutside(e));
    }

    triggerClickOutside(event) {
        if (!document.getElementsByClassName('settings-container')[0].contains(event.target)) {
            this.toggleSettingsMenu(false);
        }
    }

    toggleSettingsMenu(open) {
        this.setState({isSettingsMenuOpen: open});
    }

    render() {
        return (
            <>
                <div className={"settings-container"}>
                    <FontAwesomeIcon icon={faCog}
                                     className={(this.state.isSettingsMenuOpen) ? "settings-trigger open" : "settings-trigger"}
                                     onClick={() => this.toggleSettingsMenu(!this.state.isSettingsMenuOpen)}/>

                    <div className={(this.state.isSettingsMenuOpen) ? "settings-menu open" : "settings-menu"}>

                        <NavLink to={"/demande"} className={"add-holiday settings-item"}
                                 onClick={() => this.toggleSettingsMenu(!this.state.isSettingsMenuOpen)}>

                            <FontAwesomeIcon className={"icon"} icon={faPlus}/>
                            <p>FICHE DE DEMANDE</p>
                        </NavLink>

                        <div className={"logout settings-item"} onClick={() => {
                            this.toggleSettingsMenu(!this.state.isSettingsMenuOpen);
                            logout();
                        }}>
                            <FontAwesomeIcon className={"icon"} icon={faSignOutAlt}/>
                            <p>LOGOUT</p>
                        </div>

                    </div>
                </div>
                <div id="toggle-settings">
                    <div id="logout">
                        <FontAwesomeIcon className={"icon"} icon={faSignOutAlt}/>
                        <span onClick={() => {
                            logout();
                        }}>LOGOUT</span>
                    </div>
                </div>
            </>
        );
    }
}