import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import logo from "./res/images/esokia_logo.svg";
import loader from "./res/icons/loader.svg";
import {checkAuthentication} from "./functions/security/security";
import App from './App.jsx';
import AuthenticationFail from "./pages/Authentication/fail";
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./Redux/store";

ReactDOM.render(
    <div id={"auth-loading"}>
        <img src={logo} alt={"Esokia logo"}/>
        <h1>HOLIDAYS</h1>
        <div>
            AUTHENTICATION IN PROGRESS
            <img src={loader} alt={"loader"}/>
        </div>
    </div>
    , document.getElementById('root')
);

checkAuthentication.then((auth_data) => {
    ReactDOM.render(
        <div id={"app"}>
            {(auth_data !== null) ?

                <Provider store={store}>
                    <App authData={auth_data}/>
                </Provider>

                :

                <div id={"app_content"}>
                    <AuthenticationFail/>
                </div>
            }
        </div>
        , document.getElementById('root')
    )
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
