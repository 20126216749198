import React from "react";
import './AddHoliday.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    OutlinedInput,
    Snackbar,
    Alert,
    TextField
} from "@mui/material";
import MuiSelect from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { PickersDay, StaticDatePicker, LocalizationProvider, StaticDateRangePicker, LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { checkAuthentication } from "../../functions/security/security";
import { createNewHoliday, getReferentList, queryApiGet } from '../../functions/helpers/ajax';
import { CustomPickersDay, renderWeekPickerDay } from '../../constants/constant.js';
import { disableWeekends, validateForm } from '../../functions/function';
import moment from "moment";
import swal from 'sweetalert'

export default class AddHoliday extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api_id                 : {},
            comment                : '',
            dates                  : [null, null],
            deadlineDate           : null,
            disableCheckbox        : true,
            employerList           : {},
            employerFirstname      : {},
            employerLastname       : {},
            email                  : {},
            employerId             : {},
            endHalfDay             : false,
            firstName              : {},
            full_name              : {},
            id                     : {},
            lastName               : {},
            loading                : false,
            loggedUserData         : JSON.parse(Base64.decode(Cookies.get("sso-holidays-data"))),
            numberOfDays           : 0,
            message                : '',
            openAlert              : false,
            privateComment         : false,
            referentList           : {},
            selectedHolidayType    : '',
            startHalfDay           : false,
            showEndDate            : false,
            showInput              : true,
            showStartDate          : false,
            showTextField          : false,
            value                  : '',
            roles                  : []
        };
        this.onDateRangeSelected = this.onDateRangeSelected.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.calcHoliday = this.calcHoliday.bind(this);
        this.onStartCheckboxChange = this.onStartCheckboxChange.bind(this);
        this.onEndCheckboxChange = this.onEndCheckboxChange.bind(this);
    }

    async componentDidMount() {

        let info = {};
        await checkAuthentication.then((auth_data) => {
            info = JSON.parse(Base64.decode(Cookies.get("sso-holidays-data")));

            this.setState({
                firstName: auth_data.firstName,
                lastName: auth_data.lastName,
                email: info.email,
                employerId: info.api_id,
                userId: info.api_id,
                roles: info.roles
            });
        })
        //get list of employees for admin page
        queryApiGet('/get-active-employee').then((response) => {
            this.setState({
                ...this.state,
                employerList: response.data
            });
        });

        // get list of referent for user page
        if (info.api_id) {
            getReferentList(info.api_id).then((response) => {
                this.setState({
                    ...this.state,
                    referentList: response.data
                });
            });
        }
    }

    // employee dropdown function
    onEmployeeChange = (event) => {

        const found = Object.values(this.state.employerList).find(element => element.id === event.target.value);

        this.setState({
            employerFirstname: found.firstName,
            employerLastname: found.lastName,
            email: found.email,
            employerId: found.id,
            showInput: false,
            showTextField: true
        });
        // get list of referents for admin page
        getReferentList(found.id).then((response) => {
            this.setState({
                ...this.state,
                referentList: response.data
            });
        });
    }

    // calculate number of holidays function
    calcHoliday(start, end) {
        let currentCount = 0;
        const sDate = start;
        const eDate = end;

        let curDate = new Date(sDate.getTime());

        while (curDate <= eDate) {
            const dayOfWeek = curDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                currentCount++;
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        return currentCount;
    }

    // on select dates in calendar function
    async onDateRangeSelected(newValue) {
        this.setState({
            dates: newValue,
        });

        const startDate = newValue[0];
        const endDate = newValue[1];

        //disable-enable checkbox + show-hide selected dates info
        if (startDate) {
            this.setState({
                disableCheckbox: true,
                showStartDate: true,
                showEndDate: false
            });
        }

        if (endDate) {
            this.setState({
                disableCheckbox: false,
                showEndDate: true
            });
        }

        //display calculated number of holidays taken
        const numberOfDays = await this.calcHoliday(startDate, endDate);

        this.setState({
            numberOfDays: numberOfDays,
            startHalfDay: false,
            endHalfDay: false
        })
    }

    // start half day function
    onStartCheckboxChange = (event) => {
        this.setState({
            startHalfDay: event.target.checked
        })
        const startDate = this.state.dates[0];
        const endDate = this.state.dates[1];

        //calculation for start half day
        if(this.state.endHalfDay && moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')){
            this.setState({
                endHalfDay: false,
                startHalfDay: event.target.checked
            });
            return;
        }

        if (event.target.checked) {
            this.state.numberOfDays -= 0.5
        } else {
            this.state.numberOfDays += 0.5
        }

    }

    // end half day function
    onEndCheckboxChange = (event) => {

        this.setState({
            endHalfDay: event.target.checked
        })

        const startDate = this.state.dates[0];
        const endDate = this.state.dates[1];

        //calculation for end half day
        if(this.state.startHalfDay && moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')){
           this.setState({
               endHalfDay: event.target.checked,
               startHalfDay: false
           });
           return;
        }

        if (event.target.checked) {
            this.state.numberOfDays -= 0.5
        } else {
            this.state.numberOfDays += 0.5
        }
    }

    // function for close button in toast
    handleClose = () => {
        this.setState({
            openAlert: false
        })
    }

    previewTeams = (data) => {
        var para = document.createElement("p");
        
        data.teams.forEach(function (team) {
           if(team.type == 'TEAM_UNIT') {
               para.innerHTML += `<br/><span><b>Équipe Unitaire</b> : ${team.name}</span>`
           } else {
               para.innerHTML += `<br/><span><b>Équipe :</b> ${team.name}</span>`
           }
        });

        swal({
            title : data.full_name,
            content: para,
        });
    }

    //submit button
    onButtonClick = () => {
        //loading button
        this.setState({
            loading: true
        })

        //validate form data
        const validateData = {
            employee_id: this.state.employerId,
            start_date: this.state.dates[0],
            end_date: this.state.dates[1],
            deadline_date: this.state.deadlineDate,
            holiday_type: this.state.selectedHolidayType,
            admin: this.state.email
        }

        if (validateForm(validateData)) {
            this.setState({
                severity: "error",
                message: validateForm(validateData),
                loading: false,
                openAlert: true
            });
            return;
        }

        //post form data
        const requestData = {
            employee_id: this.state.employerId,
            start_date: moment(this.state.dates[0]).format('YYYY-MM-DD'),
            end_date: moment(this.state.dates[1]).format('YYYY-MM-DD'),
            description: this.state.comment,
            start_date_halfday: this.state.startHalfDay,
            end_date_halfday: this.state.endHalfDay,
            deadline_date: moment(this.state.deadlineDate).format('YYYY-MM-DD'),
            holiday_type: this.state.selectedHolidayType,
            is_description_private: this.state.privateComment,
            number_of_holidays: this.state.numberOfDays,
        }

        createNewHoliday(requestData).then((response) => {
            //success alert box redirected to homepage
            swal({
                title: response.data.title,
                text: response.data.message,
                icon: "success"
            }).then(function () {
                window.location.href = "/";
            });
            this.setState({
                loading: false
            });
        }).catch((err) => {
            if (err.response.data) {
                this.setState({
                    severity: "error",
                    message: err.response.data ? err.response.data.message : 'Une erreur est survenue',
                    loading: false,
                    openAlert: true
                });
            }
        });
    }

    render() {

        const renderPickerDay = (date, selectedDates, pickersDayProps) => {
            if (!this.state.deadlineDate) {
                return <PickersDay {...pickersDayProps} />;
            }

            return (
                <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                />
            );
        }

        const isAdmin = this.state.loggedUserData.roles.includes("ROLE_ADMIN");

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="title">
                        <h1> FICHE DEMANDE DE CONGÉS</h1>
                        <h2>Merci de prendre en compte les jours feriés!</h2>
                    </div>
                </div>
                <div className="card-holiday">
                    <div className="row">
                        <Box>
                            <form className="form-holiday">
                                <Grid container className="grid-form-holiday">
                                    {isAdmin ?
                                        <Grid container>
                                            {/*employee dropdown*/}
                                            <Grid item xs={12} className="grid-employer">
                                                <FormLabel htmlFor="type" className="label-info">Employé*</FormLabel>

                                                <MuiSelect onChange={this.onEmployeeChange}
                                                    defaultValue={0}
                                                    className="admin-employer">
                                                    <MenuItem value="0" disabled>Sélectionner</MenuItem>
                                                    {
                                                        Object.values(this.state.employerList)
                                                            .sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName))
                                                            .map((data) =>
                                                                <MenuItem
                                                                    key={data.id}
                                                                    name={data.firstName}
                                                                    value={data.id}>
                                                                    {data.firstName} {data.lastName}
                                                                </MenuItem>
                                                            )
                                                    }
                                                </MuiSelect>
                                            </Grid>

                                            <Grid item xs={12} className="grid-info">
                                                {/*lastname -admin page*/}
                                                <Grid item xs={4}>
                                                    <div className="col-12">
                                                        <FormLabel className="label-info">Nom</FormLabel>

                                                        <Input id="lastname"
                                                            aria-describedby="lastname"
                                                            className={this.state.showTextField ? "admin-field" : "d-none"}
                                                            value={this.state.employerLastname || ""}
                                                            inputprops={{
                                                                readOnly: true,
                                                            }} />

                                                        <Input id="lastname"
                                                            aria-describedby="lastname"
                                                            className={this.state.showInput ? "admin-field" : "d-none"}
                                                            value="nom"
                                                            disabled />
                                                    </div>
                                                </Grid>

                                                {/*firstname -admin page*/}
                                                <Grid item xs={4}>
                                                    <FormLabel htmlFor="firstname" className="label-info">Prénom</FormLabel>

                                                    <Input id="firstname"
                                                        aria-describedby="firstname"
                                                        className={this.state.showTextField ? "admin-field" : "d-none"}
                                                        value={this.state.employerFirstname || ""}
                                                        onChange={this.onEmployeeChange}
                                                        inputprops={{
                                                            readOnly: true,
                                                        }} />

                                                    <Input id="lastname"
                                                        aria-describedby="lastname"
                                                        className={this.state.showInput ? "admin-field" : "d-none"}
                                                        value="prénom"
                                                        disabled />
                                                </Grid>

                                                {/*email -admin page*/}
                                                <Grid item xs={4}>
                                                    <FormLabel htmlFor="email" className="label-info">Email</FormLabel>

                                                    <Input id="email"
                                                        type="email"
                                                        aria-describedby="email"
                                                        className={this.state.showTextField ? "admin-field" : "d-none"}
                                                        value={this.state.email || ""}
                                                        inputprops={{
                                                            readOnly: true,
                                                        }} />
                                                    <Input id="lastname"
                                                        aria-describedby="lastname"
                                                        className={this.state.showInput ? "admin-field" : "d-none"}
                                                        value="email"
                                                        disabled />
                                                </Grid>
                                            </Grid>
                                        </Grid> :

                                        <Grid item xs={12} className="grid-info">
                                            {/*lastname -user page*/}
                                            <Grid item xs={4}>
                                                <FormLabel className="label-info">Nom*</FormLabel>

                                                <Input id="lastname"
                                                    aria-describedby="lastname"
                                                    value={this.state.lastName || ""}
                                                    className="admin-field"
                                                    inputprops={{
                                                        readOnly: true,
                                                    }} />
                                            </Grid>

                                            {/*firstname -user page*/}
                                            <Grid item xs={4}>
                                                <FormLabel htmlFor="firstname" className="label-info">Prénom*</FormLabel>

                                                <Input id="firstname"
                                                    aria-describedby="firstname"
                                                    value={this.state.firstName || ""}
                                                    className="admin-field"
                                                    inputprops={{
                                                        readOnly: true,
                                                    }} />
                                            </Grid>

                                            {/*email -user page*/}
                                            <Grid item xs={4}>
                                                <FormLabel htmlFor="email" className="label-info">Email*</FormLabel>

                                                <Input id="email"
                                                    type="email"
                                                    aria-describedby="email"
                                                    value={this.state.email || ""}
                                                    className="admin-field"
                                                    inputprops={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid item xs={9}>
                                        {/*date range calendar*/}
                                        <Grid item xs={12}>
                                            <FormLabel htmlFor="startdate" className="label-holiday" ref={'textInput'}>Choisissez vos dates de début et de fin de congé*</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StaticDateRangePicker
                                                    className="calendar"
                                                    displayStaticWrapperAs="desktop"
                                                    label="date range"
                                                    shouldDisableDate={disableWeekends}
                                                    disablePast={!this.state.roles.includes('ROLE_ADMIN')}
                                                    value={this.state.dates }
                                                    allowSameDateSelection={true}
                                                    onChange={(newValue) => {
                                                        var deadline = new Date(newValue[0].getTime() - (24 * 60 * 60 * 1000) * 7);
                                                        var now = new Date(Date.now());

                                                        if (now >= deadline) {
                                                            this.setState({ deadlineDate: now });
                                                        } else {
                                                            this.setState({ deadlineDate: deadline });
                                                        }

                                                        this.onDateRangeSelected(newValue)
                                                    }}
                                                    renderDay={renderWeekPickerDay}
                                                    renderInput={(startProps, endProps) => (
                                                        <React.Fragment>
                                                            <TextField {...startProps} />
                                                            <Box sx={{ mx: 2 }}> to </Box>
                                                            <TextField {...endProps} />
                                                        </React.Fragment>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid className="dates">
                                            {/*display start date*/}
                                            <Grid item xs={12}>
                                                <FormLabel className="label-day">
                                                    Veuillez vérifier votre début de congé:
                                                </FormLabel>

                                                <FormLabel className={this.state.showStartDate ? "startdate" : "d-none"}>
                                                    {moment(this.state.dates[0]).format('DD MMMM YYYY ')}
                                                </FormLabel>
                                            </Grid>

                                            {/*display end date*/}
                                            <Grid item xs={12}>
                                                <FormLabel className="label-day ">
                                                    Veuillez vérifier votre fin de congé:
                                                </FormLabel>
                                                <FormLabel className={this.state.showEndDate ? "startdate" : "d-none"}>
                                                    {moment(this.state.dates[1]).format('DD MMMM YYYY ')}
                                                </FormLabel>
                                            </Grid>
                                        </Grid>

                                        <Grid className="checkbox">
                                            {/*start half-day checkbox*/}
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    className="label-checkbox"
                                                    value={this.state.startHalfDay}
                                                    label="Demi journée premier jour"
                                                    labelPlacement="start"
                                                    control={
                                                        <Checkbox
                                                            name="starthalfday"
                                                            className="checkbox-box"
                                                            onChange={this.onStartCheckboxChange}
                                                            value={this.state.startHalfDay}
                                                            disabled={this.state.disableCheckbox}
                                                            checked={this.state.startHalfDay}
                                                        />
                                                    }
                                                />
                                            </Grid>

                                            {/*end half-day checkbox*/}
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    value={this.state.endHalfDay}
                                                    label="Demi journée dernier jour"
                                                    labelPlacement="start"
                                                    className="label-checkbox"
                                                    control={
                                                        <Checkbox
                                                            name="endhalfday"
                                                            onChange={this.onEndCheckboxChange}
                                                            value={this.state.endHalfDay}
                                                            disabled={this.state.disableCheckbox}
                                                            checked={this.state.endHalfDay}
                                                        />
                                                    } />
                                            </Grid>

                                            {/*display number of days*/}
                                            <Grid item xs={12} className="number-of-days">
                                                <FormLabel htmlFor="numberofdays" className="label-day">
                                                    Nombre de jour: {this.state.numberOfDays}
                                                </FormLabel>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        {/*comments*/}
                                        <Grid item xs={8} className="comment">
                                            <FormLabel htmlFor="comments" className="label-holiday">
                                                Commentaires
                                            </FormLabel>

                                            <OutlinedInput
                                                label="."
                                                multiline
                                                minRows={8}
                                                className="comment-text"
                                                inputProps={{ maxLength: 255 }}
                                                onChange={(event) => { this.setState({ comment: event.target.value }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            {/*private comment checkbox*/}
                                            <FormControlLabel
                                                value="privatecomment"
                                                label="Commentaires privé"
                                                labelPlacement="start"
                                                className="private-comment"
                                                control={
                                                    <Checkbox name="privatecomment"
                                                        className="checkbox-box"
                                                        onChange={(event) => { this.setState({ privateComment: event.target.checked }) }}

                                                    />
                                                } />
                                        </Grid>
                                    </Grid>

                                    {/*half-day info*/}
                                    <Grid item xs={12}>
                                        <FormLabel htmlFor="name" className="label-day-info">
                                            Veuillez préciser dans le commentaires si le demi journée est matin ou apres midi
                                        </FormLabel>
                                    </Grid>

                                    {/*deadline date*/}
                                    <Grid item xs={4}>
                                        <FormLabel htmlFor="deadlinedate" className="label-holiday">Date limite d'acceptation*</FormLabel>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                label="Week picker"
                                                value={this.state.deadlineDate}
                                                onChange={(event) => { this.setState({ deadlineDate: event }) }}
                                                renderDay={renderPickerDay}
                                                renderInput={(params) => <TextField {...params} />}
                                                inputFormat="'Week of' MMM d"
                                                shouldDisableDate={disableWeekends}
                                                disablePast={true}
                                                maxDate={this.state.dates[0]}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    {/*referent list*/}
                                    <Grid item xs={4} className="referent-list">
                                        <FormLabel htmlFor="referents" className="label-referent">List des référents</FormLabel>

                                        <TableContainer>
                                            <Table className="table-referent">
                                                <TableBody style={{ display: "flex", flexDirection: "column" }}>
                                                    <TableRow className="row-referent">
                                                        {
                                                            Object.values(this.state.referentList)
                                                                .sort((a, b) => (a.full_name).localeCompare(b.full_name))
                                                                .map((data) =>
                                                                <TableCell
                                                                    onClick={() => this.previewTeams(data)}
                                                                    className="cell-referent"
                                                                    key={data.email}
                                                                    name={data.full_name}
                                                                    value={data.email}
                                                                >
                                                                    {data.full_name}
                                                                </TableCell>
                                                            )}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    {/*type de conge*/}
                                    <Grid item xs={4} className="grid-type">
                                        <FormLabel htmlFor="type" className="label-holiday">Type de congé*</FormLabel>
                                        <MuiSelect
                                            className="type-conge"
                                            onChange={(event) => { this.setState({ selectedHolidayType: event.target.value }) }}
                                            labelId="type"
                                            defaultValue={0}>
                                            <MuiMenuItem value="0" key={0} disabled>Sélectionner</MuiMenuItem>
                                            <MuiMenuItem key={1} value="annual">Annuel</MuiMenuItem>
                                            {this.state.roles.includes('ROLE_ADMIN') ?

                                                [
                                                    <MuiMenuItem key={2} value="sick">Maladie</MuiMenuItem>,
                                                    <MuiMenuItem key={3} value="maternity">Maternité</MuiMenuItem>,
                                                    <MuiMenuItem key={4} value="other">Autre</MuiMenuItem>
                                                ]
                                                : ""
                                            }
                                        </MuiSelect>
                                    </Grid>

                                    {/*error toast*/}
                                    <Grid item xs={12}>
                                        <Snackbar open={this.state.openAlert} autoHideDuration={6000} onClose={this.handleClose}>
                                            <Alert
                                                onClose={this.handleClose}
                                                severity="error"
                                                variant="filled"
                                                sx={{ width: '100%' }}>
                                                {this.state.message}
                                            </Alert>
                                        </Snackbar>

                                        <LoadingButton
                                            className="confirm-button"
                                            onClick={this.onButtonClick}
                                            loading={this.state.loading}
                                            variant="contained">
                                            Confirmer
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}