import {
    status_pending,
    status_valid,
    status_invalid,
    status_arbitrage,
    type_annual,
    type_sick,
    type_other,
    green,
    red,
    blue,
    pending_transform,
    valid_transform,
    invalid_transform,
    arbitrage_transform,
    annual_transform,
    sick_transform,
    other_transform, type_maternity, maternity_transform
} from "../../constants/constant";

export function transformStatus(status) {
    switch (status) {
        case status_pending:
            return pending_transform;
        case status_valid:
            return valid_transform;
        case status_invalid:
            return invalid_transform;
        case status_arbitrage:
            return arbitrage_transform;

        default :
            return pending_transform;
    }
}

export function transformHolidayType(type) {
    switch (type) {
        case type_annual:
            return annual_transform;
        case type_sick:
            return sick_transform;
        case type_other:
            return other_transform;
        case type_maternity:
            return maternity_transform;

        default :
            return annual_transform;
    }
}

export function getColor(status) {
    switch (status) {
        case status_pending:
            return blue;
        case status_valid:
            return green;
        case status_invalid:
            return red;
        case status_arbitrage:
            return blue;

        default :
            return blue;
    }
}
