export function disableWeekends(day) {
    return day.getDay() === 0 || day.getDay() === 6  ;
}

export function validateForm(validateData){
    if(!validateData.start_date && !validateData.end_date && !validateData.deadline_date && !validateData.holiday_type){
        return 'Veuillez remplir tous les champs';
    }

    if(!validateData.start_date){
        return 'Veuillez remplir votre début de congé';
    }

     if(!validateData.end_date){
        return 'Veuillez remplir votre fin de congé';
    }

     if(!validateData.deadline_date){
        return 'Veuillez remplir votre date limite d\'acceptation';
    }

      if(!validateData.holiday_type){
        return 'Veuillez remplir votre type de congé';
    }

     //to prevent the admin from taking a holiday
      if (validateData.admin === 'admin@esokia.com' || validateData.admin === 'supervision2021@esokia.com'){
        return 'Veuillez sélectionner un employé';
    }
}