import {styled} from "@mui/material/styles";
import MuiDateRangePickerDay from "@mui/lab/DateRangePickerDay";
import {PickersDay} from "@mui/lab";
import React from "react";

export const pageSize = 10;
export const holidayMessage = "loading holidays";

//Holiday Status
export const status_pending = "pending";
export const status_valid = "valid";
export const status_invalid = "invalid";
export const status_arbitrage = "arbitration";

//Transform Holiday Status
export const pending_transform = "En attente";
export const valid_transform = "Validé";
export const invalid_transform = "Refusé";
export const arbitrage_transform = "Arbitrage";

//Holiday Type
export const type_annual = "annual";
export const type_sick = "sick";
export const type_other = "other";
export const type_maternity = 'maternity'

//Transform Holiday Type
export const annual_transform = "Congé annuel";
export const sick_transform = "Congé maladie";
export const other_transform = "Congé autre";
export const maternity_transform = "Congé maternité";

export const adminText = "Modifié par l'admin";

//color
export const green = "#8abc00";
export const red = "#E94C50";
export const lightRed = "#FF968A";
export const blue = "#40C1E2";
export const white = "#fafcff";

export const DateRangePickerDay = styled(MuiDateRangePickerDay)(
    ({theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting}) => ({
        ...(isHighlighting && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...(isStartOfHighlighting && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(isEndOfHighlighting && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
    }),
);

export const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({theme, dayIsBetween, isFirstDay, isLastDay}) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));


export const renderWeekPickerDay = (date, dateRangePickerDayProps) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
};
