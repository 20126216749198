import axios from "axios";
import { url as env } from "../conf/env";
import Cookies from "js-cookie";

// get all employee list
export async function queryApiGet(link, page, itemsPerPage) {
    try {
        const url = env.api + `${link}?auth_token=${Cookies.get("sso-holidays-token")}&page=${page}&itemsPerPage=${itemsPerPage}`;
        return await axios.get(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error);
        await fetch(null);
    }
}

// get holiday for employee
export async function getEmployeeHolidays() {
    try {
        const url = env.api + `/api/employee_holidays?auth_token=${Cookies.get("sso-holidays-token")}`;
        return await axios.get(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error)
    }
}

//filter holiday by name
export async function filterHolidayByName(link, page, itemsPerPage, fullName, status) {
    try {
        const url = env.api + `${link}?auth_token=${Cookies.get("sso-holidays-token")}&page=${page}&itemsPerPage=${itemsPerPage}&employeeFullName=${fullName}&status=${status}`;
        return await axios.get(url, {
            headers: { "accept": "application/ld+json" }
        })
    } catch (error) {
        throw_error(error);
    }
}

// update status
export async function updateHolidayStatus(id, status) {
    try {
        const url = env.api + `/edit-holiday-status?auth_token=${Cookies.get("sso-holidays-token")}&id=${id}&status=${status}`;
        return await axios.post(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error);
    }
}

// get arbitrage
export async function getArbitrage(page, itemsPerPage) {
    try {
        const url = env.api + `/get-holidays-arbitration?auth_token=${Cookies.get("sso-holidays-token")}&page=${page}&itemsPerPage=${itemsPerPage}`;
        return await axios.get(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error)
    }
}

// get referent for validation page
export async function getReferent(page, itemsPerPage) {
    try {
        const url = env.api + `/get-holidays-referent?auth_token=${Cookies.get("sso-holidays-token")}&page=${page}&itemsPerPage=${itemsPerPage}`
        return await axios.get(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error)
    }
}

// get referent for login user or selected employee in fiche de demande
export async function getReferentList(userId) {
    try {
        const url = env.api + `/get-referent-list?auth_token=${Cookies.get("sso-holidays-token")}&id=${userId}`;
        return await axios.get(url,
            {
                headers: { "accept": "application/ld+json" }
            }
        )
    } catch (error) {
        throw_error(error);
    }
}

// submit form fiche de demand
export async function createNewHoliday(requestData) {
    const url = env.api + "/create-new-holiday?auth_token=" + Cookies.get("sso-holidays-token");
    return await axios.post(url, requestData)
}

function throw_error(err) {
    return err;
}

export async function getHolidayStatus(holidayId) {
    const url = env.api + `/get-holiday-status?auth-token=${Cookies.get("sso-holidays-token")}&id=${holidayId}`;
    try {
        return await axios.get(url, {
            header: {"accept": "application/ld+json"}
        });
    } catch (error) {
        throw_error(error);
    }
}
