import React from 'react';
import ListConge from "../../pages/List/list";
import AddHoliday from "../../pages/AddHoliday/AddHoliday";
import Validation from "../../pages/Validation/list";
import Arbitrage from "../../pages/Arbitrage/list";

export const routes = [

    {
        path: "/",
        comp: <ListConge/>
    },
    {
        path: "/demande",
        comp: <AddHoliday/>
    },
    {
        path: "/validation",
        comp: <Validation/>
    }
];

export const restricted_routes = [

    {
        path: "/",
        comp: <ListConge/>
    },
    {
        path: "/demande",
        comp: <AddHoliday/>
    },
    {
        path: "/arbitrage",
        comp: <Arbitrage/>
    }
];
