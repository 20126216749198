import React from "react";
import "./style.scss";
import loader from "../../res/icons/loader.svg";


export default class Loading extends React.Component {

    render() {

        return (
            <div className={"loading"}>
                <h5>{this.props.message.toUpperCase()}</h5>
                <img src={loader} alt={"loader"}/>
            </div>
        )
    }
}