//Module name
export const name = "holidays";

//Action
export const types = {
    SET_HOLIDAY_STATUS: 'SET_HOLIDAY_STATUS'
};

const defaultState = {
    status: ""
}

//Reducer
export default function holidayReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case types.SET_HOLIDAY_STATUS: {
            const {content} = action.payload;

            return {
                ...state,
                status: content
            }
        }
        default:
            return state;
    }
}

//Action creators
export const holidayActions = {
    setHoliday: content => ({
        type: types.SET_HOLIDAY_STATUS,
        payload: {
            content
        }
    })
};

//Selectors
export const holidaySelectors = {
    getHoliday: store => store[name].status
};