import React from "react"
import Paginate from "../../components/Pagination/paginate";
import "./list.scss";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {filterHolidayByName, getHolidayStatus, getReferent, updateHolidayStatus} from "../../functions/helpers/ajax";
import {
    pageSize,
    status_valid,
    status_invalid,
    status_pending,
    white,
    adminText,
    lightRed,
    holidayMessage
} from "../../constants/constant";
import {transformStatus, transformHolidayType, getColor} from "../../functions/helpers/status";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Loading from "../../components/Loading/Index";
import {connect} from 'react-redux';
import {holidayActions, holidaySelectors} from "../../Redux/modules/holidays";

class ListValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            holidayList: [],
            isBeingModify: false,
            open: false,
            modalContent: "",
            hiddenId: "",
            filter: "",
            loading: false,
            loader: false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showButton = this.showButton.bind(this);
        this.getListForValidation = this.getListForValidation.bind(this);
        this.showDescription = this.showDescription.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.filterHolidayByName = this.filterHolidayByName.bind(this);
    }

    //handle page change for pagination
    handlePageChange(event, value) {
        this.filterHolidayByName(this.state.filter, value);
        this.setState(
            {
                page: value,
                loader: false
            }
        );
    }

    //get list of holidays for referents in page validation
    getListForValidation(page) {
        this.setState({
            loader: true
        })
        getReferent(page, pageSize).then((response) => {
            this.setState({
                loader: false,
                holidayList: response.data
            });
        });
    }

    //change holiday status from pending to either valid or invalid
    handleStatus(event, id) {
        let status = event.target.value;
        this.setState({
            loading: true,
            isBeingModify: id
        })

        updateHolidayStatus(id, status).then(() => {
            this.setState({
                loading: false
            });
            this.filterHolidayByName(this.state.filter, this.state.page);
            props.setHoliday(status);
        }).catch((err) => {
            console.error(err);
        });
    }

    //show description in popup when column description is clicked
    showDescription(description) {
        this.setState({
            open: true,
            modalContent: description
        })
    }

    //close popup description
    handleClose() {
        this.setState({
            open: false
        })
    }

    //Show  buttons Valider and Refuser when button modify is clicked
    showButton(event, id) {
        this.setState({
            hiddenId: id
        });
    }

    //handle filter change for filter mechanism
    handleFilerChange(value) {
        if (value.match('(^$)|[a-zA-Z]')) {
            this.setState({
                filter: value,
                page: 1
            });
        } else {
            return null;
        }
        this.filterHolidayByName(value, 1);
    }

    //Filter holidays by employee name
    filterHolidayByName(value, page) {
        filterHolidayByName('/filter-holidays-for-referent', page, pageSize, value).then((response) => {
            this.setState({
                holidayList: response.data
            });
        }).catch((err) => {
            console.error(err);
        })
    }

    //Show status of each validator
    previewStatus = async (data) => {
        const holidayStatus = await getHolidayStatus(data.id);
        const holidays = Object.values(holidayStatus.data);

        const para = document.createElement("p");

        holidays.forEach(function (validator){
            para.innerHTML += `<br/><br/><b>${validator.full_name}</b>: <span style="background-color:${getColor(validator.status)};color:white;padding:1px 5px; border-radius: 6px">${transformStatus(validator.status)}</span>`
            if(validator.teams) {
                para.innerHTML += '</br>Teams: '
                const teamMembers = validator.teams.map(function (team) {
                    return team.name;
                })
                para.innerHTML += teamMembers.join(", ");
            }
        });

        swal({
            content: para,
        });
    }

    componentDidMount() {
        this.getListForValidation(this.state.page);
    }

    render() {
        let pageNumber = this.state.page;
        let loading = this.state.loading;
        let modify = this.state.isBeingModify;
        let modalContent = this.state.modalContent;
        let holiday = this.state.holidayList['holidays'] ?? [];
        let totalItems = this.state.holidayList['totalItems'] ?? 0;

        if (this.state.loader) {
            return (
                <Loading message={holidayMessage}/>
            );
        }

        return (
            <div>
                <input type="text" className={"form-control"} id={"txtFilterHoliday"}
                       placeholder={"Filtrer les congés par nom..."} value={this.state.filter}
                       onChange={(e) => this.handleFilerChange(e.target.value)}/>

                {holiday.length <= 0 && this.state.filter ?
                    <h1 style={{textAlign: "center"}}>Aucun congé trouvé</h1>

                    :

                    <TableContainer>
                        <Table className={"listValidation"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Employée</TableCell>
                                    <TableCell>Date début</TableCell>
                                    <TableCell>Date fin</TableCell>
                                    <TableCell>Nmb Jour</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Type congé</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Total validé</TableCell>
                                    <TableCell>Total refusé</TableCell>
                                    <TableCell>Votre voix</TableCell>
                                    <TableCell>Statut</TableCell>
                                    <TableCell>Statut final</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holiday.map(data => {
                                    let holiday_id = data.id;
                                    let referentStatus = data.referent_status;
                                    let status = data.status;
                                    let admin = data.isAdmin;
                                    let description = data.description;
                                    return (
                                        <TableRow key={holiday_id}>
                                            <TableCell>{data.employee_name}</TableCell>
                                            <TableCell>{new Date(data.start_date).toDateString()}</TableCell>
                                            <TableCell>{new Date(data.end_date).toDateString()}</TableCell>
                                            <TableCell>{data.number_of_days}</TableCell>
                                            <TableCell id="desc"
                                                       onClick={() => this.showDescription(description)}>{description}</TableCell>
                                            <TableCell>{transformHolidayType(data.holiday_type)}</TableCell>
                                            <TableCell>{data.total}</TableCell>
                                            <TableCell>{data.total_valid}</TableCell>
                                            <TableCell>{data.total_invalid}</TableCell>
                                            <TableCell id="colAction">
                                                {referentStatus === status_pending || this.state.hiddenId === holiday_id ?
                                                    <div id="action">
                                                        <button className="btn" id="btnValid" name="btnValid"
                                                                disabled={referentStatus === status_valid || admin || (loading && holiday_id === modify)}
                                                                onClick={(e) => this.handleStatus(e, holiday_id)}
                                                                value={"valid"}>Valider
                                                        </button>
                                                        <button className="btn" id="btnRefuse" name="btnInvalid"
                                                                disabled={referentStatus === status_invalid || admin || (loading && holiday_id === modify)}
                                                                onClick={(e) => this.handleStatus(e, holiday_id)}
                                                                value={"invalid"}>Refuser
                                                        </button>
                                                    </div> :
                                                    <div id="update">
                                                        <button className="btn" id="btnModify" disabled={admin}
                                                                onClick={(e) => this.showButton(e, holiday_id)}>Modifié
                                                        </button>
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell style={admin ? {backgroundColor: lightRed, color: white} :
                                                {backgroundColor: getColor(referentStatus), color: white}}>
                                                {admin ? adminText : loading && holiday_id === modify ?
                                                    <CircularProgress style={{height: "25px", width: "25px"}}
                                                                      color={"inherit"}/> : transformStatus(referentStatus)}
                                            </TableCell>
                                            <TableCell onClick={() => this.previewStatus(data)} className={"status-button"} style={{backgroundColor: getColor(status), color: white}}>
                                                {loading && holiday_id === modify ?
                                                    <CircularProgress style={{height: "25px", width: "25px"}}
                                                                      color={"inherit"}/> : transformStatus(status)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {holiday.length > 0 ?
                    <Paginate page={Number(pageNumber)} count={Math.ceil(totalItems / pageSize)}
                              onChange={this.handlePageChange}/>
                    :
                    null
                }

                {(modalContent !== null && modalContent !== "") ?
                    <Modal open={this.state.open} onClose={this.handleClose}>
                        <div className="descPopup">{this.state.modalContent}</div>
                    </Modal>
                    :
                    null
                }
            </div>
        );
    }
}

export default connect(
    state => ({
        holidayStatus: holidaySelectors.getHoliday(state)
    }),
    {
        setHolidayStatus: holidayActions.setHoliday
    }
)(ListValidation)