import React from "react";
import logo from "../../res/images/esokia_logo.svg";
import "./style.scss";
import Settings from "./Settings";
import {Base64} from "js-base64";
import Cookies from "js-cookie";
import {getEmployeeHolidays} from '../../functions/helpers/ajax';
import {url} from "../../functions/conf/env";

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: this.props.userFirstName.toUpperCase(),
            lastName: this.props.userLastName.toUpperCase(),
            employeeHolidays: {},
            loggedUserData: JSON.parse(Base64.decode(Cookies.get("sso-holidays-data")))
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    componentDidMount() {
        if (!this.state.loggedUserData.roles.includes("ROLE_ADMIN")) {
            getEmployeeHolidays().then((response) => {
                this.setState({
                    employeeHolidays: response.data['hydra:member'][0]
                });
            });
        }

        window.addEventListener('resize', function (event) {
            if (window.screen.width > 970) {
                document.getElementById("main-navbar").classList.remove("toggle-nav");
            }
        }, true);
    }

    toggleNavbar() {
        document.getElementById("main-navbar").classList.toggle('toggle-nav');
    }

    render() {
        const employeeHoliday = this.state.employeeHolidays;
        const isAdmin = this.state.loggedUserData.roles.includes("ROLE_ADMIN");
        let userName = this.state.firstName + " " + this.state.lastName;
        let userPicture = this.state.firstName.charAt(0) + this.state.lastName.charAt(0);
        return (
            <nav className="navbar-default">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsing" onClick={this.toggleNavbar}/>
                    <div className="logo">
                        <a href="/"><img src={logo} alt="Conges" height="45" width="120"/></a>
                    </div>
                </div>
                <div className="navbar-collapse main-navbar" id="main-navbar">
                    <ul className="navbar-content">
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/demande">Fiche de demande</a></li>

                        {this.state.loggedUserData.roles.includes("ROLE_LEADER") ||
                        this.state.loggedUserData.roles.includes("ROLE_MANAGER")
                            ?
                            <li><a href="/validation">Validation de conge</a></li>
                            : null
                        }
                        {isAdmin ?
                            <li><a href="/arbitrage">Arbitrage</a></li>
                            : null
                        }
                        <hr/>
                        <li><a href={url.sso}>HUB</a></li>
                    </ul>
                    {employeeHoliday ?
                        <div className="cgeCount">
                            {!isAdmin ?
                                <>
                                    <div className="sick">Congés
                                        Annuel: <span>{employeeHoliday.remaining_annual_holidays}</span></div>
                                    <div className="local">Congés
                                        Sick: <span>{employeeHoliday.remaining_sick_holidays}</span>
                                    </div>
                                </>
                                : null
                            }
                        </div> : null
                    }

                    <a href={url.sso} className="about-user">
                        <div id="user">
                            <span id="user-image">{userPicture}</span>
                            <span id="name">{userName}</span>
                        </div>
                    </a>
                    <Settings/>
                </div>
            </nav>
        );
    }
}